import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './index.css'

export default function Slide() {
    return (
        <Carousel className='Slide' autoPlay interval={3000} infiniteLoop={true} showThumbs={false} showIndicators={false}>

            <div>
                <div>
                    <img  src={'./images/banner(1).png'} alt='banner' />
                </div>
            </div>
        </Carousel>
    )
}
